/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { useScreenService } from 'hooks/useScreenService';
import { STRING } from 'shared/constants/strings';

import { ICONS_SVG } from 'common/IconsSVG';
import Image from 'common/Image';
import Link from 'common/Link';
import Typography from 'common/Typography';
import { getIconSvg } from 'utils/getIconSvg';

import { ContactCardProps } from './models';

import './ContactCard.scss';

const ContactCard: FC<ContactCardProps> = ({ contactSection, contactImage }) => {
  const { isMobile } = useScreenService();

  const { contactTitle, contactDescription, phoneSection } = contactSection;

  const iconVariant =
    contactTitle === STRING.WHATSAPP ? ICONS_SVG.VARIANT.WHATSAPP : ICONS_SVG.VARIANT.PHONE;

  return (
    <div className="contact-card" data-testid="contact-card">
      <div className="contact-card__info">
        <Typography variant="heading4" customClass="contact-card__title">
          <div className="contact-card__icon">{getIconSvg(iconVariant)}</div>
          {contactTitle}
        </Typography>
        <Typography
          customClass="contact-card__text"
          variant="paragraph1"
          dangerouslySetInnerHTML={contactDescription}
        />
        {isMobile ? (
          <Link url={`tel:${phoneSection}`} customClass="contact-card__number">
            {phoneSection}
          </Link>
        ) : (
          <p className="contact-card__number">{phoneSection}</p>
        )}
      </div>
      {contactTitle === STRING.WHATSAPP ? (
        <Image className="contact-card__code" imageData={contactImage} alt={contactImage.altText} />
      ) : null}
    </div>
  );
};

export default ContactCard;
