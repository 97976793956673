import React, { FC } from 'react';

import { Container } from 'layout';
import ContactCard from 'components/ContactCard';

import { ContactCardsProps } from './models';

import './ContactCards.scss';

const ContactCards: FC<ContactCardsProps> = ({ contactCards, contactImage }) => {
  const renderCards = contactCards.map((card) => (
    <ContactCard
      contactSection={contactCards[contactCards.indexOf(card)]}
      contactImage={contactImage}
    />
  ));

  return (
    <Container element="section" className="cards">
      {renderCards}
    </Container>
  );
};

export default ContactCards;
