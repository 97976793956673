import React, { FC } from 'react';
import { graphql } from 'gatsby';
import AccordionList from 'containers/AccordionList';
import ContactCards from 'containers/ContactCards';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner from 'components/Banner';
import { BANNER } from 'components/Banner/models.d';

import './FaqPage.scss';

const FaqPage: FC<{ data: FaqPageTypes.FaqPageSiteTypes }> = ({
  data: {
    faq: { urls, seo, faqItems, contactSection, contactImage, banner, pageName },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo, pageName }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner banner={banner} variant={BANNER.VARIANT.PRIMARY} />
      <AccordionList items={faqItems} customClass="faq-page__accordion" />
      <ContactCards contactCards={contactSection} contactImage={contactImage} />
    </Layout>
  );
};

export const query = graphql`
  query FaqPageQuery($lang: String) {
    faq(lang: { eq: $lang }) {
      ...FaqPageFragment
    }
  }
`;

export default FaqPage;
